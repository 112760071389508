<template>
  <div
    class="wrapper bg-secondary mx-auto col-10 col-lg-8 col-xl-6 px-0 mb-0"
  >
    <header class="bg-success header text-center px-2 py-2 py-xl-3 font-weight-normal">
      {{ innerText }}
    </header>
    <b-row class="mx-0 button-wrapper justify-content-center">
      <p class="divider col-12 mb-0"></p>
      <b-button
        class="text-uppercase font-size-140 my-2 my-xl-3 font-weight-bold col-9 col-sm-6 col-xl-4"
        block
        size="lg"
        variant="success"
        @click="$emit('onPopUpClose')"
      >
        Добре
      </b-button>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'commonPopUp',
  title: 'Сучасний Факторинг',
  props: {
    innerText: String,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 10px;
  box-shadow: 5px 5px 20px #0e0e0e;

  @media screen and (min-width: 1600px) {
    max-width: 800px !important;
    flex: auto !important;
  }
}

.header {
  font-size: 1.2em;
  border-radius: 10px 10px 0 0;

  @media screen and (min-width: 992px) {
    font-size: 1.4em;
  }

  @media screen and (min-width: 1600px) {
    font-size: 1.5em;
  }
}

.content {
  max-height: 55vh;
  overflow-y: auto;
}

.indent {
  padding: 0 15px;
}

.underscore {
  border-bottom: 1px solid;
}

.list {
  list-style: none;
}

.divider {
  border-top: 1px solid #fff;
}
</style>
