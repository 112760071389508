<template>
  <div class="col-2 input-group-lg">
    <input
      ref="input"
      :type="inputType"
      min="0"
      max="9"
      maxlength="1"
      pattern="[0-9]"
      v-model="model"
      class="otp-input-valid form-control text-center text-secondary p-0"
      :class="this.otpError ? 'otp-input-invalid' : false"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SingleOtpInput',
  props: {
    value: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    shouldAutoFocus: {
      type: Boolean,
    },
    inputType: {
      type: String,
      default() {
        return 'tel';
      },
    },
    isLastChild: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('login', [
      'otpError',
    ]),
  },
  data() {
    return {
      model: this.value || '',
    };
  },
  mounted() {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus();
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue;
      }
    },
    // whenever question changes, this function will run
    focus(newFocusValue, oldFocusValue) {
      // Check if focusedInput changed
      // Prevent calling function if input already in focus
      if (oldFocusValue !== newFocusValue && (this.$refs.input && this.focus)) {
        this.$refs.input.focus();
        this.$refs.input.select();
      }
    },
  },
  methods: {
    handleOnChange() {
      if (this.model.length > 1) {
        this.model = this.model.slice(0, 1);
      }
      return this.$emit('on-change', this.model);
    },
    handleOnKeyDown(event) {
      // Only allow characters 0-9, DEL, Backspace and Pasting
      const keyEvent = (event) || window.event;
      const charCode = (keyEvent.which) ? keyEvent.which : keyEvent.keyCode;
      if (this.isCodeNumeric(charCode)
        || (charCode === 8)
        || (charCode === 86)
        || (charCode === 46)) {
        this.$emit('on-keydown', event);
      } else {
        keyEvent.preventDefault();
      }
    },
    isCodeNumeric(charCode) {
      // numeric keys and numpad keys
      return (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105);
    },
    handleOnPaste(event) {
      return this.$emit('on-paste', event);
    },
    handleOnFocus() {
      this.$refs.input.select();
      return this.$emit('on-focus');
    },
    handleOnBlur() {
      return this.$emit('on-blur');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/my_custom";

.otp-input {
  background-color: transparent !important;
  font-weight: 700 !important;
  -webkit-transition: all $transition-time ease !important;
  -moz-transition: all $transition-time ease !important;
  -o-transition: all $transition-time ease !important;
  transition: all $transition-time ease !important;

  @include media-breakpoint-only(xs) {
    font-size: 1.3rem !important
  }
  @include media-breakpoint-only(sm) {
    font-size: 1.5rem !important
  }
  @include media-breakpoint-only(md) {
    font-size: 1.7rem !important
  }
  @include media-breakpoint-only(lg) {
    font-size: 1.85rem !important
  }
  @include media-breakpoint-only(xl) {
    font-size: 2rem !important
  }

  &:focus {
    box-shadow: 0 0 0 , 0 0 0 !important;
    outline: 0 none !important;
    background-color: transparent !important;
  }

  &-valid {
    @extend .otp-input;

    border-color: $custom-green !important;

    &:focus {
      border-color: $custom-green !important;
    }
  }

  &-invalid {
    @extend .otp-input;

    border-color: $custom-red !important;

    &:focus {
      border-color: $custom-red !important;
    }
  }
}
</style>
