<template>
  <div>
    <b-form>
      <b-row
        no-gutters
      >
        <div class="col input-group-lg">
          <input
            class="form-control password-input-valid border-radius"
            :class="this.passwordError ? 'password-input-invalid' : false"
            autocomplete="off"
            type="password"
            :value="password"
            @input="$emit('update:password', $event.target.value)"
          >
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PasswordInput',
  props: {
    password: String,
  },
  computed: {
    ...mapState('login', ['passwordLengthError', 'passwordError', 'errorsGlobal']),
    getPasswordId() {
      let passwordError = false;

      if (this.errorsGlobal !== null) {
        const passwordErrors = ['passwordLengthError'];
        Object.values(this.errorsGlobal).forEach((error) => {
          if (error.code in passwordErrors) {
            passwordError = true;
          }
        });
      }

      return {
        'password-input': !passwordError,
        'password-input-invalid': passwordError,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px #269efa inset !important;
    -webkit-text-fill-color: #fff !important;
  }
}

.password-input {
  @import "../styles/my_custom";

  $placeholder-opacity: .6;

  color: $custom-white !important;
  font-weight: 700 !important;
  text-align: center !important;
  -webkit-transition: all $transition-time ease !important;
  -moz-transition: all $transition-time ease !important;
  -o-transition: all $transition-time ease !important;
  transition: all $transition-time ease !important;

  @include media-breakpoint-only(xs) {
    font-size: 1.3rem !important;
  }
  @include media-breakpoint-only(sm) {
    font-size: 1.5rem !important;
  }
  @include media-breakpoint-only(md) {
    font-size: 1.7rem !important;
  }
  @include media-breakpoint-only(lg) {
    font-size: 1.85rem !important;
  }
  @include media-breakpoint-only(xl) {
    font-size: 2rem !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }

  &:focus {
    box-shadow: 0 0 0 , 0 0 0 !important;
    outline: 0 none !important;
  }

  &-valid {
    @extend .password-input;

    border-color: $custom-blue !important;
    background-color: $custom-blue !important;

    &:focus {
      border-color: $custom-blue !important;
    }
  }

  &-invalid {
    @extend .password-input;

    border-color: $custom-red !important;
    background-color: $custom-red !important;

    &:focus {
      border-color: $custom-red !important;
    }
  }
}

</style>
