<template>
  <b-col
    cols="12"
    class="my-3"
  >
    <b-row
      no-gutters
    >
      <b-col
        class="
                    d-flex
                    align-items-center
                    justify-content-end"
      >
        <div
          class="
                      d-flex
                      circle-breadcrumb
                      bg-success
                      justify-content-center
                      align-items-center"
        >
          <div class="bg-light">
          </div>
        </div>
      </b-col>
      <b-col
        cols="1"
        class="px-2"
      >
        <hr
          class="border-dark border-3"
        >
      </b-col>
      <b-col
        class="
                    d-flex
                    align-items-center
                    justify-content-start"
      >
        <div
          class="
                      circle-breadcrumb
                      d-flex
                      justify-content-center
                      align-items-center"
          v-bind:class="circleBreadcrumbExtraClass"
        >
          <div class="bg-light">
          </div>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    stage: Number,
  },
  computed: {
    circleBreadcrumbExtraClass() {
      return {
        'bg-dark': this.stage < 2,
        'bg-success': this.stage >= 2,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/my_custom';

$first-circle:  0.9rem;
$second-circle: 0.65rem;

div.circle-breadcrumb {
  height: $first-circle;
  width: $first-circle;
  border-radius: 50%;
}
div.circle-breadcrumb > div {
  height: $second-circle;
  width: $second-circle;
  border-radius: 50%;
}
</style>
