<template>
  <div
    id="auth"
    class="bg-light"
  >
    <b-container>
      <b-row
        class="vh-100 text-center"
        align-v="center"
        align-h="center"
      >
        <b-col
          md="9"
          lg="7"
          class="py-5"
        >
          <b-row class="justify-content-center">
            <transition
              name="zoom"
              mode="out-in"
              appear
            >
              <b-col
                class="align-self-center px-md-0 mx-md-0 px-5"
                sm="7"
                md="5"
                cols="8"
              >
                <b-img
                  src="@/assets/logo.png"
                  fluid
                  alt="Логотип"
                >
                </b-img>
              </b-col>
            </transition>
            <transition
              name="zoom"
              mode="out-in"
              appear
            >
              <b-col
                offset-md="2"
                sm="5"
                md="5"
                cols="12"
                class="align-self-center mt-4 mt-md-0"
              >
                <p class="font-size-100 mb-0 text-center text-md-left text-dark d-md-flex
                          justify-content-md-center">
                  14 років на ринку та
                  <br>
                  більше 1 000 000 заявок
                  <br>
                  на кредити
                </p>
              </b-col>
            </transition>
          </b-row>
          <b-row
            align-h="center"
          >
            <transition
              name="zoom"
              mode="out-in"
              appear
            >
              <breadcrumbs :stage="stage" />
            </transition>
          </b-row>
          <b-row
            align-h="center"
            class="wrapper"
          >
            <b-col>
              <b-overlay
                :show="loading"
                rounded="lg"
                variant="light"
                opacity="0.7"
                spinner-variant="success"
                spinner-type="grow"
                spinner-small
              >
                <transition
                name="zoom"
                mode="out-in"
                appear
              >
                  <div
                    class="bg-light shadow more-rounded py-4 px-3 px-sm-4 pb-md-4 px-md-5
                          py-xl-5 border border-success"
                  >
                    <div class="px-xl-2">
                      <b-row
                        class="d-flex justify-content-center"
                        no-gutters
                      >
                        <p
                          class="header text-white mb-3 text-dark"
                        >
                          Авторизація в Кабінеті
                        </p>
                      </b-row>
                      <b-row
                        no-gutters
                        class="pb-4 mb-2 mb-sm-3 mb-md-0 pb-md-3 mt-xl-3"
                      >
                        <b-col
                          col
                        >
                          <p
                            class="text-success m-0 text-left text-dark input-title"
                          >
                            Твій номер мобільного телефону
                      </p>
                        </b-col>
                        <transition
                          name="zoom"
                          mode="out-in"
                          appear
                        >
                          <b-col
                            class="d-flex align-items-end"
                            cols="4"
                            v-if="stage > 1"
                          >
                            <b-button
                              block
                              size="sm"
                              class="text-white"
                              variant="primary"
                              @click="toFirstStage"
                            >
                              Змінити
                            </b-button>
                          </b-col>
                        </transition>
                      </b-row>
                      <b-row
                        no-gutters
                      >
                        <b-col
                          class="d-lg-block arrow-to-upper-wrapper col-2 col-md-1"
                        >
                          <b-img
                            class="arrow-to-upper"
                            src="@/assets/arrow.svg"
                            fluid
                            alt="Arrow"
                          >
                          </b-img>

                        </b-col>
                        <b-col
                          md="12"
                        >
                          <b-row>
                            <b-col>
                              <phone-input-form
                                v-bind:phone-number.sync="phoneForm.phoneNumber"
                                v-bind:country-code="phoneForm.countryCode"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <transition
                        name="zoom"
                        mode="out-in"
                        appear
                      >
                        <b-row
                          v-if="this.stage === 2 && this.isStaff === true"
                          no-gutters
                          class="pb-2 pt-3"
                        >
                          <b-col
                            col
                          >
                            <p
                              class="text-white m-0 text-left font-size-115"
                            >
                          <span
                            class="text-primary font-size-100"
                          >
                            Вкажи пароль
                          </span>
                            </p>
                          </b-col>
                        </b-row>
                      </transition>
                      <transition
                        name="zoom"
                        mode="out-in"
                        appear
                      >
                        <b-row
                          v-if="this.stage === 2 && this.isStaff === true"
                          no-gutters
                        >
                          <b-col
                            class="d-none d-lg-block arrow-to-upper-wrapper"
                            lg="1"
                          >
                            <b-img
                              class="arrow-to-upper"
                              src="@/assets/arrow.svg"
                              fluid
                              alt="Arrow"
                            >
                            </b-img>
                          </b-col>
                          <b-col
                            md="12"
                          >
                            <b-row>
                              <b-col>
                                <password-input
                                  v-if="isStaff"
                                  v-bind:password.sync="passwordForm.password"
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </transition>
                      <transition
                        name="zoom"
                        mode="out-in"
                        appear
                      >
                        <b-row
                          v-if="this.stage === 2 && this.isBanned === true"
                          no-gutters
                        >
                          <b-row class="mb-5 mt-5">
                            <b-col>
                              <span
                                style="font-weight: 700"
                                class="text-danger text-center small-fs">
                                Ви зробили більше п'яти запитів за кодом.
                                Ви зможете повторити запит через годину!
                              </span>
                            </b-col>
                          </b-row>
                        </b-row>
                      </transition>
                      <transition
                        name="zoom"
                        mode="out-in"
                        appear
                      >
                        <b-row
                          v-if="this.stage === 2 && this.isBanned === false"
                          no-gutters
                        >
                          <b-col
                            md="12"
                          >
                            <b-row
                              class="mt-4 my-xl-3 mb-2"
                              align-h="center"
                            >
                              <b-col
                                sm="10"
                              >
                                <b-row class="mb-3">
                                  <b-col>
                              <span
                                class="text-dark text-center small-fs"
                              >
                                Для підтвердження номера телефону введіть отриманий код.
                                Цей код є вашим електронним підписом.
                              </span>
                                  </b-col>
                                </b-row>
                                <otp-input
                                  ref="otpInput"
                                  :num-inputs="otp.length"
                                  :should-auto-focus="false"
                                  :is-input-num="true"
                                  @on-change="handleOnChange"
                                  @on-complete="onPasswordPaste"
                                />
                                <b-row class="mt-2">
                                  <b-col>
                              <span
                                class="text-dark text-center font-size-100"
                              >
                                Код з смс
                              </span>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </transition>

                      <transition
                        name="zoom"
                        mode="out-in"
                        appear
                      >
                        <b-row
                          v-if="stage === 3"
                          no-gutters
                        >
                          <b-col
                            md="12"
                          >
                            <b-row
                              class="mt-4 my-xl-3 mb-2"
                              align-h="center"
                            >
                              <b-col
                                sm="10"
                              >
                                <b-row class="mb-3">
                                  <b-col>
                              <span
                                class="text-dark text-center small-fs"
                              >
                                Введіть Ваш РНОКПП(Раніше ІПН):
                              </span>
                                  </b-col>
                                </b-row>
                                <Itn-input
                                  v-bind:itn.sync="itnNumber.code"
                                  ref="IpnInput"
                                  :should-auto-focus="false"
                                  :is-input-num="true"
                                  @on-change="handleOnChange"
                                />
                                <b-col>
                                  <span
                                    v-if="setItnError"
                                    class="text-center small-fs errorStyle"
                                  >
                                Введіть правильний РНОКПП
                              </span>
                                </b-col>
                                <b-row class="mt-2">
                                  <b-col>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </transition>

                      <transition
                        name="error-appear"
                        mode="out-in"
                        appear
                      >
                        <b-row
                          v-if="errorsGlobal !== null
                      && (errorsGlobal[0].code === 'otpLengthError'
                      || errorsGlobal[0].code === 'phoneNumberLengthError')"
                          no-gutters
                          class="mt-2"
                        >
                          <b-col
                            md="12"
                          >
                            <b-row
                              class="mb-1"
                              align-h="center"
                            >
                              <b-col>
                                <transition-group name="error-appear">
                                  <p
                                    class="text-danger m-0"
                                    v-for="error in this.errorsGlobal"
                                    :key="error.code"
                                  >
                                    {{ error.message }}
                                  </p>
                                </transition-group>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </transition>
                      <b-row
                        no-gutters
                        class="mt-3"
                      >
                        <b-col
                          class="d-none d-lg-block"
                          lg="1"
                        >
                        </b-col>
                        <b-col
                          md="12"
                        >
                          <b-row>
                            <b-col
                              v-if="isStaff"
                            >
                              <b-button
                                class="text-uppercase btn-font-size border-radius font-weight-bold"
                                block
                                size="lg"
                                variant="outline-success"
                                @click="submitItem(true)"
                              >
                                Перейти на my.sf
                              </b-button>
                              <b-button
                                class="text-uppercase btn-font-size border-radius font-weight-bold
                                        mt-3"
                                block
                                size="lg"
                                variant="primary"
                                @click="submitItem(false)"
                              >
                                Перейти на silver
                              </b-button>
                            </b-col>
                            <b-col
                              v-else
                            >
                              <b-button
                                class="text-uppercase btn-font-size font-weight-bold
                                        border-radius"
                                block
                                size="lg"
                                variant="outline-success"
                                @click="onClickHandler"
                              >
                                продовжити
                              </b-button>
                              <b-button
                                v-if="!isProd"
                                class="idBtn"
                                pill
                                variant="primary"
                                @click="bankIdRequest"
                              >
                                ID
                              </b-button>
                            </b-col>
                          </b-row>
                          <b-row class="agreements mx-auto mt-3 my-xl-4">
                            <b-row>
                              <p class="mb-0 agreement small-fs pt-1 pt-xl-0 text-dark">
                                Натискаючи "Продовжити", я підтверджую, що ознайомлений зі змістом
                                <a
                                  href="https://slvr.one/!/rhcru"
                                  class="link text-success"
                                  target="_blank"
                                >Правил</a>,
                                <a
                                  href="https://slvr.one/!/A0tOV"
                                  class="link text-success"
                                  target="_blank"
                                >Публічною пропозицією</a>, приймаю їх в повному обсязі та надаю
                                <a
                                  href="https://slvr.one/!/94jcg"
                                  class="link text-success"
                                  target="_blank"
                                >Згоду</a> на обробку персональних даних та доступ до моєї
                                кредитної історії
                              </p>
                            </b-row>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
              </transition>
              </b-overlay>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <pop-up v-if="this.toCall === true && this.isValidPhone === true">
        <CommonPopUp @onPopUpClose="tooglePopUpHandler()"
                     innerText=" СХОЖЕ ЩО ВИ НОВИЙ КОРИСТУВАЧ НАШОГО СЕРВІСУ
      МИ НАПРАВИЛИ ВАШ ЗАПИТ У КОЛЛ ЦЕНТР І ВАМ ЗАТЕЛЕФОНУЮТЬ ЯК НАЙШВИДШЕ"
        />
      </pop-up>
      <pop-up v-if="this.itnSuccess && this.isValidPhone === false">
        <CommonPopUp @onPopUpClose="tooglePopUpHandler()"
        innerText="В системі вже є клієнт з таким РНОКПП (Раніше ІПН),
         але з іншим номером телефону. Будь ласка, зверніться до кол-центру"
        />
      </pop-up>
      <pop-up
        v-if="showAgreementsPopUp"
      >
        <agreements
          @onPopUpClose="toggleAgreementsPopUp(false)"
        ></agreements>
      </pop-up>

      <pop-up
        v-if="showLendingRulesPopUp"
      >
        <lending-rules
          @onPopUpClose="toggleLendingRulesPopUp(false)"
        />
      </pop-up>
    </b-container>
  </div>
</template>

<script>
import ItnInput from '@/components/ItnInput';
import PhoneInputForm from '@/components/PhoneInputForm';
import OtpInput from '@/components/OtpInput';
import PasswordInput from '@/components/PasswordInput';
import PopUp from '@/components/Common/PopUp';
import Agreements from '@/components/PopUpContent/Agreements';
import { mapState, mapActions } from 'vuex';
import LendingRules from '@/components/PopUpContent/LendingRules';
import Breadcrumbs from '../components/Common/Breadcrumbs';
import CommonPopUp from '../components/PopUpContent/CommonPopUpNotifier';
import { BASE_URL } from '../api/REST';

export default {
  name: 'Auth',
  title: 'Сучасний Факторинг',
  components: {
    Breadcrumbs,
    LendingRules,
    PhoneInputForm,
    OtpInput,
    PasswordInput,
    PopUp,
    Agreements,
    ItnInput,
    CommonPopUp,
  },
  data() {
    return {
      showAgreementsPopUp: false,
      showLendingRulesPopUp: false,
      setItnError: false,
      passwordForm: {
        password: '',
      },
      otp: {
        length: 4,
        code: '',
        valid: false,
      },
      itnNumber: {
        length: 10,
        code: '',
        valid: false,
      },
      phoneForm: {
        phoneNumber: '',
      },
    };
  },
  methods: {
    bankIdRequest() {
      window.location.href = `${BASE_URL}api/v1/bank-id-auth`;
    },
    toggleAgreementsPopUp(bool) {
      this.showAgreementsPopUp = bool;
    },
    tooglePopUpHandler() {
      this.toFirstStage();
      this.$store.dispatch('login/closeAuthPopUp');
    },
    toggleLendingRulesPopUp(bool) {
      this.showLendingRulesPopUp = bool;
    },
    handleOnChange(value) {
      this.otp.code = value;
      this.otp.valid = this.otp.length === value.length;
    },
    onPasswordPaste(e) {
      this.otp.code = e;
      this.otp.valid = this.otp.length === e.split('').length;
    },
    toFirstStage() {
      this.passwordForm.password = '';
      this.otp.valid = false;
      this.otp.code = '';
      this.$store.dispatch('login/toFirstStageStore');
    },
    checkPhone(phoneNumber) {
      this.$store.dispatch('login/checkPhoneStore', { phoneNumber });
    },
    sendPhone(phone) {
      this.$store.dispatch('login/sendPhoneStore', { phone });
    },
    checkOTP(otp) {
      this.$store.dispatch('login/checkOTPStore', { otp });
    },
    checkPassword(passwordForm) {
      this.$store.dispatch('login/checkPasswordStore', { passwordForm });
    },
    sendClient(code, phone) {
      this.$store.dispatch('login/getRedirectUrl', { code, phone });
    },
    sendNew(code, phone) {
      this.$store.dispatch('login/checkNewClient', { code, phone });
    },
    sendItn(itn, phone) {
      this.$store.dispatch('login/checkNewClientItn', { itn, phone });
    },
    sendStaff(code, phone, password, urlType) {
      this.$store.dispatch('login/getRedirectUrl', {
        code,
        phone,
        password,
        urlType,
      });
    },
    changeUrlType(isMySF) {
      this.$store.commit('login/CHANGE_STATE', {
        key: 'urlType',
        value: isMySF ? 'client' : 'staff',
      });
    },
    submitItem(isMySF) {
      this.$store.dispatch('login/clearErrors');
      if (this.stage === 1) {
        this.checkPhone(this.phoneForm.phoneNumber);
        if (this.phoneNumberLengthError === false) {
          this.sendPhone(`${this.phoneForm.phoneNumber}`);
        }
      } else if (this.stage === 2) {
        this.checkOTP(this.otp);
        if (this.otpLengthError === false) {
          this.$store.dispatch('login/clearErrors');
          if (this.isNew === false && this.isStaff === false) {
            this.sendClient(
              this.otp.code,
              `${this.phoneForm.phoneNumber}`,
            );
          } else if (this.isStaff === true) {
            this.changeUrlType(isMySF);
            this.checkPassword(this.passwordForm);
            if (this.passwordLengthError === false) {
              this.sendStaff(
                `${this.otp.code}`,
                `${this.phoneForm.phoneNumber}`,
                `${this.passwordForm.password}`,
                `${this.urlType}`,
              );
            }
          } else if (this.isNew === true) {
            this.checkOTP(this.otp);
            if (this.otpLengthError === false) {
              this.$store.dispatch('login/clearErrors');
            }
            this.sendClient(
              this.otp.code,
              `${this.phoneForm.phoneNumber}`,
            );
            // this.sendNew();
          }
        }
      } else if (this.stage === 3) {
        if (this.itnNumber.code.length !== 10) {
          this.setItnError = true;
          const timeout = setTimeout(() => {
            this.setItnError = false;
            clearTimeout(timeout);
          }, 2000);
        } else {
          this.sendItn(this.itnNumber.code, this.phoneForm.phoneNumber);
        }
      }
    },
    onClickHandler() {
      this.submitItem(true);
    },
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.submitItem(true);
      }
    });
  },

  computed: {
    isProd: {
      get() {
        if (process.env.VUE_APP_IS_PROD === 'true') {
          return true;
        }
        return false;
      },
    },
    ...mapState('login', [
      'otpLengthError',
      'phoneNumberLengthError',
      'passwordLengthError',
      'stage',
      'isNew',
      'isStaff',
      'error',
      'errorsGlobal',
      'loading',
      'urlType',
      'itn',
      'toCall',
      'itnSuccess',
      'isBanned',
      'isValidPhone',
    ]),
    ...mapActions('login', [
      'closeCallMePopUp',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.idBtn{
  border-radius: 20px;
  position: absolute;
  top:160px;
  right:0;
}

@import '../styles/my_custom';

$first-circle:  1.25rem;
$second-circle: 0.65rem;

.header {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 500;

  @media screen and (min-width: 600px) {
    font-size: 1.4em;
  }
}

.input-title {
  font-size: 0.9rem;

  @media screen and (min-width: 768px) {
    position: relative;
    right: 20px;
  }

  @media screen and (min-width: 992px) {
    font-size: 1rem;
  }
}

.agreements {
  text-align: left;
  width: 80%;

  @media screen and (min-width: 600px) {
    width: 85%;
  }
}

.small-fs {
  font-size: 0.8rem;

  @media screen and (min-width: 1200px) {
    font-size: 0.9rem;
  }
}

.wrapper {
  margin-bottom: 150px;
}

.checkbox {
  top: -3px;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.error-appear-enter-active,
.error-appear-leave-active {
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.error-appear-leave-active {
  animation-direction: reverse;
}

.arrow-to-upper-wrapper {
  position: relative;
}

.arrow-to-upper {
  margin-top: -.7rem;
  padding-left: .2rem;
  padding-right: .2rem;

  width: 28px;
  position: absolute;
  left: -15px;
  top: -22px;

  @media screen and (min-width: 576px) {
    width: 32px;
    left: -20px;
    top: -25px;
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    left: -38px;
    top: 0px;
  }

  @media screen and (min-width: 1200px) {
    width: 90%;
    left: -40px;
  }

}

.btn-font-size {
  font-size: 0.9rem;

  @media screen and (min-width: 1200px) {
    font-size: 1rem;
  }
}

.font-size-100 {
  font-size: 1rem;
}

.font-size-115 {
  font-size: 1.15rem;
}

.font-size-140 {
  font-size: 1.4rem;
}

.more-rounded {
  border-radius: $more-border-radius;
}

hr.border-secondary {
  border-width: 0.15rem;
}

.phone-number-wrapper {
  @media screen and (max-width: 768px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
.errorStyle{
  color: red;
}
@media screen and (max-width: 1200px) {
  .idBtn{
    top:120px;
  }
}
@media screen and (max-width: 500px) {
  .idBtn{
    top:160px;
    right: 5px;
  }
}
</style>
