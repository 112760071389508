<template>
  <div>
    <b-row
      no-gutters
      class="phone-input-valid border-radius"
      :class="this.phoneNumberError ? 'phone-input-invalid' : false"
    >
      <div
        class="input-wrapper position-relative mx-auto text-left"
        :style="{ width: inputWidth }"
      >
        <input
          ref="phoneInput"
          id="phone-input"
          class="form-control pl-1 phone-input-number-valid py-0 text-left border-radius"
          :class="this.phoneNumberError ? 'phone-input-number-invalid' : false"
          autocomplete="off"
          maxlength="19"
          type="tel"
          :readonly="this.stage !== 1"
          v-mask="'+380 (##) ###-##-##'"
          @click="setCursorToEnd"
          @input="onInput"
          @focus="toggleMask(true);"
          @blur="toggleMask(false);"
          :placeholder="phoneInputPlaceholder"
        >
        <span ref="placeholder" class="secondary-placeholder position-absolute"></span>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapState } from 'vuex';

export default {
  name: 'PhoneInputForm',
  props: {
    phoneNumber: String,
    countryCode: String,
  },
  data() {
    return {
      mobileNumber: '',
      inputWidth: '0px',
      placeholderWidth: 0,
      phoneInputPlaceholder: '+380 (99) 999-99-99',
    };
  },
  mask: '#XSAa',
  directives: { mask },
  computed: {
    ...mapState('login', ['phoneNumberLengthError', 'phoneNumberError', 'stage']),
  },
  mounted() {
    setTimeout(this.setInputWidth, 550);
    const windowWidth = document.documentElement.clientWidth;
    const desktopWidth = 1250;
    if (windowWidth > desktopWidth) {
      window.addEventListener('resize', () => {
        setTimeout(this.setInputWidth, 1000);
      });
    }
  },
  methods: {
    onInput(e) {
      // eslint-disable-next-line no-unused-expressions
      e.target.value.length > 0 ? this.toggleMask(false) : this.toggleMask(true);
      this.$emit('update:phoneNumber', e.target.value);
    },
    setCursorToEnd() {
      const { phoneInput } = this.$refs;
      if (phoneInput.value === '') { phoneInput.value = '+380 ('; }
    },
    toggleMask(bool) {
      const contentForPlaceholder = '+380 (99) 999-99-99';
      const { placeholder, phoneInput } = this.$refs;
      const classToShow = 'active';

      if (bool && phoneInput.value.length === 0) {
        this.phoneInputPlaceholder = '';
        placeholder.classList.add(classToShow);
      } else {
        this.phoneInputPlaceholder = contentForPlaceholder;
        placeholder.classList.remove(classToShow);
      }
    },
    getFontSize(element) {
      return window.getComputedStyle(element, null).getPropertyValue('font-size');
    },
    createHiddenSpan(fontSize, content) {
      const span = document.createElement('span');
      span.textContent = content;
      // position: absolute; visibility: hidden;
      span.setAttribute('style',
        `font-size: ${fontSize}; position: absolute; visibility: hidden; font-weight: 700;`);
      return span;
    },
    removeElement(element) {
      element.remove();
    },
    getElementWidth(element) {
      return element.getBoundingClientRect().width;
    },
    // eslint-disable-next-line consistent-return
    getPlaceholderWidth() {
      return new Promise((resolve) => {
        const phoneInput = document.querySelector('#phone-input');
        const fontSize = this.getFontSize(phoneInput);
        const span = this.createHiddenSpan(fontSize, phoneInput.placeholder);
        document.body.appendChild(span);
        if (this.getElementWidth(span) > 0) {
          this.placeholderWidth = this.getElementWidth(span);
          resolve({
            width: this.getElementWidth(span),
            span,
          });
        } else {
          resolve({
            width: this.placeholderWidth,
            span,
          });
        }
      });
    },
    setInputWidth() {
      this.getPlaceholderWidth()
        .then((data) => {
          this.inputWidth = `${data.width + 2}px`;
          this.removeElement(data.span);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  width: auto;
  margin: 0 auto;
  padding-left: initial !important;
  padding-right: initial !important;
}

#phone-input {
  width: 100%;
}

.secondary-placeholder {
  display: none;
  top: 7px;
  left: 0;

  &.active {
    display: block;
  }
}

.phone-input {
  @import "../styles/my_custom";

  $placeholder-opacity: .6;

  color: $custom-white !important;
  font-weight: 700 !important;
  -webkit-transition: all $transition-time ease !important;
  -moz-transition: all $transition-time ease !important;
  -o-transition: all $transition-time ease !important;
  transition: all $transition-time ease !important;

  @include media-breakpoint-only(xs) {
    font-size: 1.3rem !important;
  }
  @include media-breakpoint-only(sm) {
    font-size: 1.5rem !important;
  }
  @include media-breakpoint-only(md) {
    font-size: 1.7rem !important;
  }
  @include media-breakpoint-only(lg) {
    font-size: 1.85rem !important;
  }
  @include media-breakpoint-only(xl) {
    font-size: 2rem !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }

  &:focus {
    box-shadow: 0 0 0 , 0 0 0 !important;
    outline: 0 none !important;
  }

  &-valid {
    @extend .phone-input;

    border-color: $custom-green !important;
    background-color: $custom-green !important;

    &:focus {
      border-color: $custom-green !important;
    }
  }

  &-invalid {
    @extend .phone-input;

    border-color: $custom-red !important;
    background-color: $custom-red !important;

    &:focus {
      border-color: $custom-red !important;
    }
  }

  /*  &-number {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }*/

  &-country-code {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &-number-valid {
    @extend .phone-input-valid;
    //@extend .phone-input-number;
  }

  &-number-invalid {
    @extend .phone-input-invalid;
    //@extend .phone-input-number;
  }

  &-country-code-valid {
    @extend .phone-input-valid;
    @extend .phone-input-country-code
  }

  &-country-code-invalid {
    @extend .phone-input-invalid;
    @extend .phone-input-country-code
  }
}
</style>
