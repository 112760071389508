<template>
  <div
    class="wrapper bg-secondary mx-auto px-0 mb-0"
  >
    <header class="bg-success header text-center px-2 py-2 py-xl-3 font-weight-normal">
      Правила кредитування
    </header>

    <div class="content">
      <iframe
        class="iframe"
        allowfullscreen=""
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        src="https://cdn-do.sfactor.com.ua/static/documents/1.%20%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20(31.05.21).pdf"></iframe>
    </div>

    <b-row class="mx-0 mt-n2 button-wrapper justify-content-center">
      <p class="divider col-12 mb-0"></p>
      <b-button
        class="text-uppercase font-size-140 my-2 my-xl-3 font-weight-bold col-9 col-sm-6 col-xl-4"
        block
        size="lg"
        variant="success"
        @click="$emit('onPopUpClose')"
      >
        продовжити
      </b-button>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'LendingRules',
};
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 10px;
  box-shadow: 5px 5px 20px #0e0e0e;
  width: 90vw;

  @media screen and (min-width: 800px) {
    width: 700px;
  }

  @media screen and (min-width: 1200px) {
    width: 900px;
  }

  @media screen and (min-width: 1600px) {
    flex: auto !important;
  }
}

.header {
  font-size: 1.2em;
  border-radius: 10px 10px 0 0;

  @media screen and (min-width: 992px) {
    font-size: 1.4em;
  }

  @media screen and (min-width: 1600px) {
    font-size: 1.5em;
  }
}

.content {
  overflow-y: auto;
}

.indent {
  padding: 0 15px;
}

.underscore {
  border-bottom: 1px solid;
}

.list {
  list-style: none;
}

.divider {
  border-top: 1px solid #fff;
}

.iframe {
  height: 65vh;
  width: 90vw;

  @media screen and (min-width: 800px) {
    width: 700px;
  }

  @media screen and (min-width: 1200px) {
    width: 900px;
  }

  @media screen and (min-width: 1200px) {
    height: 75vh;
  }
}
</style>
