<template>
  <b-overlay
    rounded="sm"
    class="position-fixed d-flex align-content-center justify-content-center"
  >
    <b-row
      ref="popUp"
      class="pop-up"
      align-content="center"
    >
      <slot></slot>
    </b-row>
  </b-overlay>
</template>

<script>

export default {
  name: 'PopUp',
  mounted() {
    setTimeout(() => this.$refs.popUp.classList.add('active'));
  },
  beforeDestroy() {
    this.$refs.popUp.classList.remove('active');
  },
};
</script>

<style lang="scss" scoped>
.b-overlay-wrap {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: #212121b3;
}

.pop-up {
  opacity: 0;
  transition: 0.2s opacity ease-in;

  &.active {
    opacity: 1;
  }
}
</style>
