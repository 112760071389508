<template>
  <div>
    <b-form>
      <b-row
        no-gutters
      >
        <div class="col input-group-lg"
        >
          <input
            class="form-control itn-input-valid inputItn"
            :class="this.itnError ? 'itn-input-invalid' : false"
            autocomplete="off"
            maxlength="10"
            type="text"
            v-mask="'##########'"
            @input="onInput"
            placeholder="9999999999"
            >
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapState } from 'vuex';

export default {
  name: 'ItnInput',
  props: {
    itn: String,
  },
  methods: {
    onInput(e) {
      // eslint-disable-next-line no-unused-expressions
      this.$emit('update:itn', e.target.value);
    },
  },
  directives: { mask },
  computed: {
    ...mapState('login', ['itnLengthError', 'itnError']),
  },
};
</script>

<style lang="scss" scoped>

.itn-input {
  @import "../styles/my_custom";

  $placeholder-opacity: .6;

  color: $custom-white !important;
  font-weight: 700 !important;
  text-align: center !important;
  -webkit-transition: all $transition-time ease !important;
  -moz-transition: all $transition-time ease !important;
  -o-transition: all $transition-time ease !important;
  transition: all $transition-time ease !important;

  @include media-breakpoint-only(xs) {
    font-size: 1.3rem !important;
  }
  @include media-breakpoint-only(sm) {
    font-size: 1.5rem !important;
  }
  @include media-breakpoint-only(md) {
    font-size: 1.7rem !important;
  }
  @include media-breakpoint-only(lg) {
    font-size: 1.85rem !important;
  }
  @include media-breakpoint-only(xl) {
    font-size: 2rem !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $custom-white !important;
    opacity: $placeholder-opacity !important;
  }

  &:focus {
    box-shadow: 0 0 0, 0 0 0 !important;
    outline: 0 none !important;
  }

  &-valid {
    @extend .itn-input;
    border-color: $custom-blue !important;
    background-color: $custom-blue !important;

    &:focus {
      border-color: $custom-blue !important;
    }
  }

  &-invalid {
    @extend .itn-input;
    border-color: $custom-red !important;
    background-color: $custom-red !important;

    &:focus {
      border-color: $custom-red !important;
    }
  }
}
</style>
