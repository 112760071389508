<template>
  <div
    class="wrapper bg-secondary mx-auto col-10 col-lg-8 col-xl-6 px-0 mb-0"
  >
    <header class="bg-success header text-center px-2 py-2 py-xl-3 font-weight-normal">
      ЗГОДИ НА ЗБІР, ЗБЕРІГАННЯ ТА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ, ТА ПЕРЕДАЧУ ЇХ У БЮРО КРЕДИТНИХ
      ІСТОРІЙ.
    </header>

    <div class="content custom-scroll px-2 px-xl-3">
      <b-row class="mx-0 py-2 py-xl-3">
        <b-col
          cols="0"
          sm="5"
          lg="6"
        ></b-col>
        <b-col>
          <p class="mb-0">
            ТОВАРИСТВУ З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ
            <br>
            «СУЧАСНИЙ ФАКТОРИНГ»
          </p>
          <p class="mb-0 underscore">
            код за ЄДРПОУ: 35310044
          </p>
          <p class="mb-0">
            м.Київ, вул.Велика Васильківська, 39А.
          </p>
        </b-col>
      </b-row>

      <b-row class="mx-0 flex-column text-center font-weight-bold" align-content="center">
        <p class="mb-0">
          ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ ТА ІНШОЇ ІНФОРМАЦІЇ
        </p>
        <p class="text-center mb-0">
          (надалі за текстом – «Згода» у відповідному відмінку)
        </p>
      </b-row>

      <b-row class="mx-0 mt-2">
        <p class="mb-0">
          <span class="indent"></span>
          Я, (надалі «Клієнт»), виступаючи в якості суб'єкта персональних даних,
          надаю ТОВАРИСТВУ З
          ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «СУЧАСНИЙ ФАКТОРИНГ», код за ЄДРПОУ:35310044 (надалі –
          «Фінансова
          установа» у відповідному відмінку) згоду на здійснення без обмежень будь-яких дій щодо
          обробки моїх персональних даних (у тому числі але не виключно: прізвище, ім’я та по
          батькові; адреса місця реєстрації і фактичного проживання; місце народження; дата, місяць
          і рік народження; громадянство; фотографія документа, що посвідчує особу або моя особиста
          фотографія; ідентифікаційний номер платника податків та паспортні дані; найменування
          та реквізити роботодавця або навчального закладу; номер мобільного телефону, який визначаю
          та декларую перед Фінансовою установою та будь-якими третіми особами, як мій фінансовий
          номер, та прошу Фінансову установу визначити як додатковий ідентифікатор та основний
          спосіб комунікації, що належить мені особисто (знаходиться виключно в моєму особистому
          користуванні), має достатній рівень захисту та захищений від доступу третіх осіб;
          адреса електронної пошти; інша інформація, що надається мною Фінансовій установі
          та підпадає під визначення персональних даних) з метою:
        </p>
        <ul class="list px-0 mx-0 mb-0">
          <li>
            一 надання мені пропозицій фінансових продуктів і послуг Фінансовою установою;
          </li>
          <li>
            一 надання мені знижок і пільгових умов надання позик та/або кредитів;
          </li>
          <li>
            一 аналіз моїх даних та моє обслуговування як Клієнта Фінансової установи;
          </li>
          <li>
            一 просування послуг Фінансовою установою;
          </li>
          <li>
            一 отримання мною фінансової інформації;
          </li>
          <li>
            一 здійснення ідентифікації для цілей запобігання та протидії легалізації (відмиванню)
            доходів одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню
            розповсюдження
            зброї масового знищення;
          </li>
          <li>
            一 прийняття Фінансовою установою рішення щодо укладення договору кредиту/позики;
          </li>
          <li>
            一 надання Фінансовою установою фінансових послуг та провадження іншої діяльності
            відповідно до статуту Фінансової установи та законодавства України;
          </li>
          <li>
            一 виконання умов договорів (правочинів), що будуть укладені між мною і Фінансовою
            установою/між Фінансовою установою та іншими особами, що матимуть відношення до
            укладення,
            виконання та/або супроводження зобов’язань що можуть виникнути та/або виникнуть
            з підстав
            укладених договорів (правочинів) між мною та Фінансовою установою;
          </li>
          <li>
            一 реалізації та захисту прав сторін за укладеними договорами (правочинами);
          </li>
          <li>
            一 направлення мені інформаційних, рекламних повідомлень та пропозицій щодо послуг
            Фінансової установи, її партнерів, за допомогою програм/програмних комплексів/каналів
            зв’язку у тому числі але не виключно, за допомогою skype, Telegram, Viber, WhatsApp,
            смс-повідомлень, push-повідомлень, електронної пошти (email), та/або засобах зв'язку
            у соціальних мережах (Facebook та ін.)тощо;
          </li>
          <li>
            一 виконання інших повноважень, функцій, обов’язків Фінансової установи, що не суперечать
            законодавству України та укладеним договорам. При цьому Фінансова установа
            уповноважується
            здійснювати обробку персональних даних в обсязі інформації, що була/буде отримана
            Фінансовою установою від мене особисто, від моїх представників, від третіх осіб, у т.ч.
            бюро кредитних історій (ПРИВАТНЕ АКЦІОНЕРНЕ ТОВАРИСТВО «ПЕРШЕ ВСЕУКРАЇНСЬКЕ БЮРО
            КРЕДИТНИХ ІСТОРІЙ» (код за ЄДРПОУ 33691415, місцезнаходження: м. Київ, Дніпровський
            район, вул. Євгена Сверстюка, буд. 11); ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ
            «УКРАЇНСЬКЕ БЮРО КРЕДИТНИХ ІСТОРІЙ» (код за ЄДРПОУ 33546706, місцезнаходження:
            м. Київ, вул. Грушевського, 1-Д); ПРИВАТНЕ АКЦІОНЕРНЕ ТОВАРИСТВО «МІЖНАРОДНЕ
            БЮРО КРЕДИТНИХ ІСТОРІЙ» (код за ЄДРПОУ 34299140, місцезнаходження: м. Київ,
            пр. Перемоги,
            буд. 65, оф. 306)), інших осіб, представником яких я виступаю, стала відома під час
            надання Фінансовою установою послуг, або отримана із загальнодоступних джерел, змінювати
            /доповнювати мої персональні дані за інформацією третіх осіб, звертатися до третіх осіб
            для їх перевірки. З метою реалізації прав сторін за укладеними договорами (правочинами),
            забезпечення якості і безпеки обслуговування, надаю згоду на аудіозапис/запис моїх
            телефонних розмов з працівниками Фінансової установи, фото/відео-зйомку як в приміщеннях
            Фінансової установи так і поза її межами, у тому числі під час комунікації за допомогою
            програмних комплексів (програм) Фінансової установи, так і за допомогою різноманітних
            залучених програмних комплексів (програм) що використовуються для комунікації
            та програм,
            що дозволяють відео/аудіо та/або інший спосіб комунікації, у тому числі, але
            не виключно,
            за допомогою email, skype, Telegram, Viber, WhatsApp, та інших систем комунікації, що
            існують та/або виникнуть у майбутньому, на магнітний та/або електронний носій та надаю
            згоду на використання Фінансовою установою результатів записів/зйомок, у т.ч. як
            доказів.
            Без отримання додаткової письмової згоди та окремого повідомлення, я надаю Фінансовій
            установі згоду поширювати мої персональні дані, здійснювати їх передачу третім особам,
            у тому числі за межі України, іноземним суб’єктам відносин, або надавати доступ до них
            третім особам, зокрема, але не виключно:
          </li>
          <li>
            一 для забезпечення виконання третіми особами (на розсуд Фінансової установи)
            своїх функцій або надання послуг Фінансовій установі, зокрема але не виключно,
            Кредитним брокерам/кредитним посередникам, Торгівельним мережам (продавцям/менеджерам
            /представникам та представництвам) в яких здійснюється придбавання товарів та/або послуг
            за кошти отримані на умовах кредиту/позики, аудиторам, страховим компаніям, оцінювачам,
            кредитним рокерам /кредитним посередникам, платіжним системам, установам, що здійснюють
            ідентифікацію, авторизацію та процесинг операцій, банкам-контрагентам та іншим особам,
            якщо такі функції та послуги стосуються діяльності Фінансової установи, здійснюваних
            нею операцій, платіжних та інших інструментів, або є необхідними для надання Фінансовою
            установою відповідних послуг, а також партнерам Фінансової установи;
          </li>
          <li>
            一 при настанні підстав для передачі третім особам Комерційної/конфіденційної інформації
            згідно з законодавством України або відповідно до умов укладених договорів (правочинів);
          </li>
          <li>
            一 особам, що надають Фінансовій установі послуги з організації поштових відправлень,
            телефонних дзвінків, інтернетпровайдерів, відправлень смс-повідомлень, skype, Telegram,
            Viber, WhatsApp, push-повідомлень, відправлень електронною поштою інформації щодо
            виконання укладених з Фінансовою установою договорів (правочинів) (про строки виконання
            та розмір зобов’язань перед Фінансовою установою, тощо), інформаційних, рекламних
            повідомлень та пропозицій щодо послуг Фінансової установи, її партнерів;
          </li>
          <li>
            一 до бюро кредитних історій для отримання та формування кредитної історії, а також
            особам, що надають Фінансовій установі послуги зі стягнення заборгованості, та іншим
            особам у зв’язку зі стягненням простроченої заборгованості перед Фінансовою установою;
          </li>
          <li>
            一 будь-якій особі, яка придбає або отримує в забезпечення права вимоги за
            Кредитом/позикою;
          </li>
          <li>
            一 страховику - з метою реалізації прав та/або виконання зобов’язань Фінансової установи,
            як вигодонабувача;
          </li>
          <li>
            一 особам, що надають Фінансовій установі послуги зі зберігання клієнтських документів,
            створення та зберігання їх електронних копій (архівів, баз даних), а також особам, що
            надають послуги/ забезпечують іншу діяльність Фінансової установи, що не суперечить
            законодавству України. Погоджуюсь із тим, що мої заперечення щодо обробки Фінансовою
            установою моїх персональних даних не позбавляють Фінансову установу права на обробку
            моїх персональних даних в межах та обсягах, визначених цією Згодою та законодавством
            України, та є підставою для припинення надання Фінансовою установою послуг за укладеними
            договорами (правочинами). З урахуванням умов цієї Згоди, засвідчую, що я в належній
            формі
            та в повному обсязі повідомлений про склад та зміст моїх персональних даних, що були
            зібрані Фінансовою установою, про мету збору моїх персональних даних та про осіб, яким
            передаються мої персональні дані, а також повідомлений про свої права, визначені Законом
            України «Про захист персональних даних». Я повідомлений про те, що на сайті Фінансової
            установи додатково можу ознайомитися з повідомленням Фінансової установи про порядок
            обробки персональних даних клієнтів, про права суб’єктів персональних даних, передбачені
            Законом України «Про захист персональних даних». Усвідомлюючи обсяг та характер
            зобов’язань Фінансової установи щодо збереження комерційної та конфіденційної
            інформації,
            також надаю Фінансовій установі згоду здійснювати розкриття (передачу) інформації,
            яка становить комерційну таємницю та/або іншу конфіденційну інформацію, що стала відомою
            Фінансовій установі у процесі обслуговування та отримання послуг Фінансової установи:
          </li>
          <li>
            一 особам, що приймають участь в переказі коштів, зокрема, банкам-кореспондентам,
            платіжним системам та їх учасникам, відправникам та отримувачам грошових коштів,
            іншим установам, що здійснюють ідентифікацію, авторизацію чи процесинг переказів;
          </li>
          <li>
            一 приватним і юридичним особам, організаціям та іншим особам у зв’язку з
            укладанням/виконанням укладеного з Фінансовою установою договору ( правочину),
            для реалізації чи захисту прав та обов’язків сторін за такими правочинами (зокрема
            аудиторам, посередникам на ринку фінансових послуг, оцінщикам, операторам телефонного
            зв’язку, компаніям зі збору заборгованості, архівним установам тощо), у тому числі для
            забезпечення виконання цими особами своїх функцій або надання послуг Фінансової установи
            відповідно до укладених договорів (правочинів) та під їх зустрічні зобов’язання про
            нерозголошення інформації;
          </li>
          <li>
            一 з метою здійснення фінансового моніторингу або виконання Фінансовою установою вимог
            FATCA, зокрема, при наданні звітності та інформації на запити банків та інших осіб;
          </li>
        </ul>
        <p class="mb-0">
          <span class="indent"></span>
          Фактом погодження та надання цієї Згоди
          є проставлення мною відмітки у електронній формі
          під цією Згодою, що є підтвердженням того, що я, як суб’єкт персональних даних, надаю
          ТОВ «СУЧАСНИЙ ФАКТОРИНГ» згоду на обробку та використання своїх персональних даних
          беззастережно і без обмежень відповідно до вимог Закону.
        </p>
        <p class="mb-0">
          <span class="indent"></span>
          Згода на обробку персональних даних надається строком на 5 (п’ять) років з дати її
          надання, або на інший строк, якщо такий передбачений законодавством.
        </p>
        <p class="mb-0">
          <span class="indent"></span>
          Я, виступаючи в якості суб’єкта персональних даних, повідомлений Фінансовій установі
          про зобов’язання останнього як суб’єкта первинного фінансового моніторингу під час
          здійснення ідентифікації обробляти мої персональні дані для цілей запобігання та
          протидії легалізації (відмиванню) доходів, одержаних злочинним шляхом, фінансуванню
          тероризму та фінансуванню розповсюдження зброї масового знищення.
        </p>

        <p class="mb-0 font-weight-bold text-center mx-auto py-2">
          ЗГОДА НА ОБРОБКУ ТЕЛЕКОМУНІКАЦІЙНИХ ДАНИХ
        </p>

        <p>
          <span class="indent"></span>
          Також маючи на меті отримання фінансових послуг та грошових коштів на умовах
          кредиту/позики від Фінансової установи, надаю та підтверджую свою згоду, усім
          учасникам/виконавцям, у тому числі але не обмежуючись, Операторам мобільного зв’язку
          (без обмежень, без застережень); посередникам (у тому числі кредитним
          посередникам/кредитним брокерам); торгівельним майданчикам (як фізичним так
          і електронним) та їх працівникам/предстаникам; та або буд-яким третім особам,
          на оброблення інформації про надання та отримання мною телекомунікаційних послуг,
          у тому числі щодо MSISDN для визначення Коефіцієнта в рамках користування послугами
          мережі рухомого (мобільного) зв’язку.
        </p>
      </b-row>
    </div>

    <b-row class="mx-0 button-wrapper justify-content-center">
      <p class="divider col-12 mb-0"></p>
      <b-button
        class="text-uppercase font-size-140 my-2 my-xl-3 font-weight-bold col-9 col-sm-6 col-xl-4"
        block
        size="lg"
        variant="success"
        @click="$emit('onPopUpClose')"
      >
        продовжити
      </b-button>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Agreements',
};
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 10px;
  box-shadow: 5px 5px 20px #0e0e0e;

  @media screen and (min-width: 1600px) {
    max-width: 800px !important;
    flex: auto !important;
  }
}

.header {
  font-size: 1.2em;
  border-radius: 10px 10px 0 0;

  @media screen and (min-width: 992px) {
    font-size: 1.4em;
  }

  @media screen and (min-width: 1600px) {
    font-size: 1.5em;
  }
}

.content {
  max-height: 55vh;
  overflow-y: auto;
}

.indent {
  padding: 0 15px;
}

  .underscore {
    border-bottom: 1px solid;
  }

  .list {
    list-style: none;
  }

  .divider {
    border-top: 1px solid #fff;
  }
</style>
